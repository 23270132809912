import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../css/accountpage.css';
import RegisterPopup from '../components/RegisterPopup';
import { postToApi, deleteAccount, fetchOrderDetails, fetchFavorites, resetPassword} from '../utils/MainFunctionality'; 
import { UserContext } from '../utils/UserContext';
import ItemGallery from '../components/ItemGallery'; // Import the ItemGallery component
import { removeFromFavorites as removeFromFavoritesApi } from '../utils/MainFunctionality'; 


const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePassword = (password) => {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d{3,})(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z]).{8,}$/;
  return passwordRegex.test(password);
};

const validatePhone = (phone) => {
  const phoneRegex = /^\d{8}$/;
  return phone === '' || phoneRegex.test(phone);
};

const AccountPage = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // Initialize useLocation
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || 'My Orders';
  const [selectedOption, setSelectedOption] = useState('My Orders');
  const { user, setUser } = useContext(UserContext);

  //reset details
  const [isPopupOpen, setIsPopupOpen] = useState(user === null);
  // const [name, setName] = useState(user?.name || '');
  // const [email, setEmail] = useState(user?.email || '');
  // const [phone, setPhone] = useState(user?.phone || '');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null); // State for selected order details
  const [favorites, setFavorites] = useState([]); // State for favorites
  const [errors, setErrors] = useState({});


  const removeFromFavorites = async (position, itemId) => {
    try {
      // Optimistically update the UI
      const updatedFavorites = favorites.filter(item => item.id !== itemId);
      setFavorites(updatedFavorites);

      const response = await removeFromFavoritesApi(position);
      if (!response) {
        console.log('Item successfully removed from favorites:', response);
      } else {
        console.error('Failed to remove item from favorites.');
        // Revert the UI update if the API call fails
        setFavorites(favorites);
      }
    } catch (error) {
      console.error('Error removing item from favorites:', error);
      // Revert the UI update if the API call fails
      setFavorites(favorites);
    }
  };

  useEffect(() => {
    setSelectedOption(initialTab);
  }, [initialTab]);

  useEffect(() => {
    if (user && selectedOption === 'Favourites') {
      fetchFavorites(user.id).then(setFavorites);
    }
  }, [user, selectedOption]);


  const handleOptionClick = (option) => {
    if (option === 'Sign Out') {
      setUser(null); 
      navigate('/');
    } 
    else {
      setSelectedOption(option);
      if (option === 'Favourites') {
        navigate('/My-Account?tab=Favourites'); // Navigate to the favourites path
      } else if (option === 'My Orders') {
        navigate('/My-Account?tab=My Orders'); // Navigate to the orders path
      }
      else if(option === 'Edit Personal Details') {
        navigate('/My-Account?tab=Edit Personal Details'); //Navigate to Edit Details path
      }
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    navigate('/');
  };


  const handleViewDetails = async (orderId) => {
    // Fetch order details
    const orderDetails = await fetchOrderDetails(orderId);
    setSelectedOrderDetails(orderDetails); // Set the selected order details
  };

  const handleUpdateDetails = async (e) => {
    e.preventDefault();
    const newErrors = {};
  
    if (!user.name) newErrors.name = 'Name is required.';
    if (!user.email) newErrors.email = 'Email is required.';
    if (user.email && !validateEmail(user.email)) newErrors.email = 'Please enter a valid email address.';
    if (user.phone && !validatePhone(user.phone)) newErrors.phone = 'Phone number must be exactly 8 digits.';
  
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;
  
    try {
      const updateBody = {
        system: 'accounting',
        file: 'people',
        record: user.id,
        contents: [
          { id: 'name', value: user.name },
          { id: 'email', value: user.email },
          { id: 'phone', value: user.phone },
        ],
      };
      await postToApi('$TableModify', updateBody);
  
      setUser({ ...user, name: user.name, email: user.email, phone: user.phone });
      alert('Details updated successfully.');
    } catch (error) {
      console.error('Error updating details:', error);
      alert('Failed to update details.');
    }
  };


  const handleDeleteAccount = async () => {
    const confirmed = window.confirm('Are you sure you want to delete your account? This action cannot be undone.');
    if (!confirmed) return;

    try {
      const success = await deleteAccount(user.id, user.email_id);
      if (success) {
        setUser(null);
        navigate('/');
        alert('Account deleted successfully.');
      } else {
        alert('Failed to delete account.');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      alert('Failed to delete account.');
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const newErrors = {};
  
    if (!currentPassword) newErrors.currentPassword = 'Current password is required.';
    if (!newPassword) newErrors.newPassword = 'New password is required.';
    if (newPassword && !validatePassword(newPassword)) newErrors.newPassword = 'New password must contain at least 4 letters, 3 numbers, one capital character, and one special character.';
    if (newPassword !== confirmPassword) newErrors.confirmPassword = 'New password and confirm password do not match.';
  
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;
  
    try {
      const success = await resetPassword(user.email, currentPassword, newPassword);
      if (success) {
        alert('Password updated successfully.');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        alert('Failed to update password.');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      alert('Failed to update password.');
    }
  };

  const renderOrders = () => {
    if (!user || !user.orders || user.orders.length === 0) {
      return <p>No orders yet.</p>;
    }
    return (
      <div className="orders">
        <h2>My Orders</h2>
        <table>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Date</th>
              <th>Total</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {user.orders.map((order) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{order.date}</td>
                <td>€{order.total}</td>
                <td style={{ width: '150px' }}>
                  <button onClick={() => handleViewDetails(order.id)} className="view-details-btn">
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderOrderDetails = () => {
    if (!selectedOrderDetails) return null;
  
    return (
      <div className="order-details">
        <h2>Order Details</h2>
        <table className="order-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Price</th>
              <th>Quanity</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {selectedOrderDetails.map((detail, index) => (
              <tr key={index}>
                <td>{detail.notes}</td>
                <td>€{detail.price}</td>
                <td>{detail.quantity}</td>
                <td>€{detail.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={() => setSelectedOrderDetails(null)} className="back-btn">
          Back to Orders
        </button>
      </div>
    );
  };
  

  const renderFavourites = () => {
    if (!favorites || favorites.length === 0) {
      return <p>No favourites yet.</p>;
    }

    return (
      <div className="favourites">
        <h2>Favourites</h2>
        <ItemGallery items={favorites} removeFromFavorites={removeFromFavorites}/>
      </div>
    );
  };

  const renderEditPersonalDetails = () => (
    <div className="edit-personal-details">
      <h2>Edit Personal Details</h2>
      <form onSubmit={handleUpdateDetails} className="edit-details-form">
        <div className="details-form-group">
          <label htmlFor="name">Name</label>
          <input
            placeholder="Enter your name"
            className="input"
            name="name"
            type="text"
            id="name"
            value={user.name}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            required
          />
          {errors.name && <div className="error">{errors.name}</div>}
        </div>
        <div className="details-form-group">
          <label htmlFor="email">Email</label>
          <input
            placeholder="Enter your email"
            className="input"
            name="email"
            type="email"
            id="email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            required
          />
          {errors.email && <div className="error">{errors.email}</div>}
        </div>
        <div className="details-form-group">
          <label htmlFor="phone">Phone</label>
          <input
            placeholder="Enter your phone number"
            className="input"
            name="phone"
            type="text"
            id="phone"
            value={user.phone}
            onChange={(e) => setUser({ ...user, phone: e.target.value })}
          />
          {errors.phone && <div className="error">{errors.phone}</div>}
        </div>
        <button type="submit" className="update-details-btn mb-4">Update Details</button>
      </form>
      <button onClick={handleDeleteAccount} className="delete-account-btn">
        Delete Account
      </button>
    </div>
  );

  const renderChangePassword = () => (
    <div className="change-password">
      <h2>Change Password</h2>
      <form onSubmit={handleChangePassword} className="change-password-form">
        <div className="details-form-group">
          <label htmlFor="currentPassword">Current Password</label>
          <input
            placeholder="Enter your current password"
            className="input"
            name="currentPassword"
            type="password"
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
          {errors.currentPassword && <div className="error">{errors.currentPassword}</div>}
        </div>
        <div className="details-form-group">
          <label htmlFor="newPassword">New Password</label>
          <input
            placeholder="Enter your new password"
            className="input"
            name="newPassword"
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          {errors.newPassword && <div className="error">{errors.newPassword}</div>}
        </div>
        <div className="details-form-group">
          <label htmlFor="confirmPassword">Confirm New Password</label>
          <input
            placeholder="Confirm your new password"
            className="input"
            name="confirmPassword"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          {errors.confirmPassword && <div className="error">{errors.confirmPassword}</div>}
        </div>
        <button type="submit" className="update-details-btn mb-4">Change Password</button>
      </form>
    </div>
  );

  return (
    <div className="account-page">
      {user ? (
        <>
          <div className="header">
            <div className="user-circle">
              {user.initials}
            </div>
            <h1>Hi, {user.name}</h1>
          </div>
          <div className="main-content">
            <div className="menu">
              <div onClick={() => handleOptionClick('My Orders')} className={`menu-item ${selectedOption === 'My Orders' ? 'active' : ''}`}>My Orders</div>
              <div onClick={() => handleOptionClick('Favourites')} className={`menu-item ${selectedOption === 'Favourites' ? 'active' : ''}`}>Favourites</div>
              <div onClick={() => handleOptionClick('Edit Personal Details')} className={`menu-item ${selectedOption === 'Edit Personal Details' ? 'active' : ''}`}>Edit Personal Details</div>
              <div onClick={() => handleOptionClick('Change Password')} className={`menu-item ${selectedOption === 'Change Password' ? 'active' : ''}`}>Change Password</div>
              <Link to="/Contact" className="menu-item">Contact Us</Link>
              <div onClick={() => handleOptionClick('Sign Out')} className="menu-item">Sign Out</div>
            </div>
            <div className="content">
              {selectedOption === 'My Orders' && (selectedOrderDetails ? renderOrderDetails() : renderOrders())}
              {selectedOption === 'Favourites' && renderFavourites()}
              {selectedOption === 'Edit Personal Details' && renderEditPersonalDetails()}
              {selectedOption === 'Change Password' && renderChangePassword()}
            </div>
          </div>
        </>
      ) : (
        
        isPopupOpen && <RegisterPopup onClose={handleClosePopup} isRegisterMode={true} />
      )}
    </div>
  );
};

AccountPage.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired,
    orders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        total: PropTypes.string.isRequired,
      })
    ).isRequired,
    favourites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,
        mainImageUrl: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
};

export default AccountPage;