import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../utils/CartContext';
import { UserContext } from '../utils/UserContext'; // Assuming you have a UserContext to get user details
import { v4 as uuidv4 } from 'uuid'; // Import the v4 method for generating GUIDs
import '../css/checkoutpage.css'; // Import your CSS file for styling
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx'; // Library to parse Excel files
import { registerOrderWithPaymentProvider, updateCustomerAddress, validateVoucherCode } from '../utils/MainFunctionality';
import { useNavigate } from 'react-router-dom';

const CheckoutPage = () => {
  const { cartItems } = useContext(CartContext);
  const { user, setUser } = useContext(UserContext); // Get user details from UserContext
  const navigate = useNavigate();

  const generateNumericUUID = () => {
    const timestamp = Date.now().toString(); // Current timestamp in milliseconds
    const randomPart = Math.floor(Math.random() * 100); // Random 2 digits
    return timestamp.slice(-6) + randomPart; // Combine last 6 digits of timestamp with random 2 digits
  };

  const [billingDetails, setBillingDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    street: '',
    postCode: '',
    city: '',
    phone: '',
  });

  const [saveDetails, setSaveDetails] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [voucherCode, setVoucherCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [voucherMessage, setVoucherMessage] = useState('');
  const [voucherError, setVoucherError] = useState('');
  const [saveDetailsError, setSaveDetailsError] = useState('');
  const [orderNumber, setOrderNumber] = useState(generateNumericUUID()); // Generate a new GUID for the order number

  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [deliveryLocation, setDeliveryLocation] = useState('');
  const [locationOptions, setLocations] = useState([]);
  const [shippingCost, setShippingCost] = useState(0);

  useEffect(() => {
    if (user) {
      setBillingDetails({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email || '',
        street: user.addr1 || '',
        postCode: user.zip || '',
        city: user.city || '',
        phone: user.phone || '',
      });
    }
  }, [user]);

  useEffect(() => {
    // Load Excel file and extract locations
    const loadLocationsFromExcel = async () => {
      try {
        const response = await fetch('media/akis.xlsx'); // Update the path to your Excel file
        const blob = await response.blob();
        const data = await blob.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(sheet);
        const extractedLocations = rows.map((row) => row.ΑΝΤΙΠΡΟΣΩΠΟΣ); // Assuming 'Location' column exists
        setLocations(extractedLocations);
      } catch (error) {
        console.error('Failed to load locations:', error);
      }
    };

    loadLocationsFromExcel();
  }, []);

  // Calculate subtotal dynamically
  const calculateSubtotal = () => {
    return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  const subtotal = calculateSubtotal();
  const total = (subtotal + shippingCost - discount);

  const handleCheckout = async () => {
    console.log('Delivery Method:', deliveryMethod);
    console.log('Delivery Location:', deliveryLocation);
    const errors = {};
    if (!billingDetails.firstName) errors.firstName = 'First name is required';
    if (!billingDetails.lastName) errors.lastName = 'Last name is required';
    if (!billingDetails.email) errors.email = 'Email is required';
    if (!billingDetails.street) errors.street = 'Street is required';
    if (!billingDetails.postCode) errors.postCode = 'Post code is required';
    if (!billingDetails.city) errors.city = 'City is required';
    if (!billingDetails.phone) errors.phone = 'Phone number is required';
    if (!deliveryMethod) errors.deliveryMethod = 'Please select a delivery method.';
    if (!deliveryLocation) errors.deliveryLocation = 'Please select a delivery location.';

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    try {
      const description = 'Order Payment Test';

      // Save order number and delivery method to local storage
      localStorage.setItem('orderNumber', orderNumber);
      localStorage.setItem('deliveryMethod', deliveryLocation + '' + deliveryLocation);

      // If user is not signed in, save the details as a temporary user
      if (!user) {
        const tempUser = {
          firstName: billingDetails.firstName,
          lastName: billingDetails.lastName,
          email: billingDetails.email,
          addr1: billingDetails.street,
          zip: billingDetails.postCode,
          city: billingDetails.city,
          phone: billingDetails.phone,
          guest: true,
        };
        localStorage.setItem('tempUser', JSON.stringify(tempUser));
      }

      console.log('Total:', total*100);
      const { formUrl } = await registerOrderWithPaymentProvider((total * 100), orderNumber, description, deliveryLocation);
      window.location.href = formUrl; // Redirect to payment URL

      if (saveDetails) {
        if (!user) {
          setSaveDetailsError('Please register or log in to save your details.');
        } else {
          const response = await updateCustomerAddress(user.email, user.id, billingDetails.street, billingDetails.postCode);
          if (response) {
            // Update user context with new address details
            setUser((prevUser) => ({
              ...prevUser,
              addr1: billingDetails.street,
              zip: billingDetails.postCode,
            }));
          }
        }
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      alert('Failed to process payment.');
    }

  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleCheckboxChange = (e) => {
    setSaveDetails(e.target.checked);
    if (e.target.checked && !user) {
      setSaveDetailsError('Please register or log in to save your details.');
    } else {
      setSaveDetailsError('');
    }
  };

  const handleVoucherChange = (e) => {
    setVoucherCode(e.target.value);
  };

  const handleVoucherRedeem = async () => {
    try {
      const discountAmount = await validateVoucherCode(voucherCode);
      if (discountAmount) {
        if (discountAmount > subtotal) {
          setVoucherError('Voucher cannot be applied when subtotal exceeds voucher amount.');
          setVoucherMessage('');
        } else {
          setDiscount(discountAmount);
          setVoucherMessage('Voucher code applied successfully!');
          setVoucherError('');
        }
      } else {
        setVoucherError('Invalid voucher code.');
        setVoucherMessage('');
      }
    } catch (error) {
      console.error('Error validating voucher code:', error);
      setVoucherError('Failed to validate voucher code.');
      setVoucherMessage('');
    }
  };

  const handleDeliveryMethodChange = (e) => {
    const selectedMethod = e.target.value;
    setDeliveryMethod(selectedMethod);
    if (selectedMethod === 'AKIS EXPRESS') {
      setShippingCost(3.00);
      setValidationErrors((prev) => ({ ...prev, deliveryMethod: '' }));
    } else {
      setShippingCost(0); // Default to 0 if no valid method is selected
    }
  };

  const handleDeliveryLocationChange = (e) => {
    setDeliveryLocation(e.target.value);
    setValidationErrors((prev) => ({ ...prev, deliveryLocation: '' }));
  };

  return (
    <div className="checkout-container">
      {/* Personal Information Form */}
      <div className="checkout-form">
        <div className="title pb-3">Checkout Details</div>
        <form className="billing-form">
          <input type="text" name="firstName" placeholder="First name*" value={billingDetails.firstName} onChange={handleInputChange} required />
          {validationErrors.firstName && <p className="error">{validationErrors.firstName}</p>}
          <input type="text" name="lastName" placeholder="Last name*" value={billingDetails.lastName} onChange={handleInputChange} required />
          {validationErrors.lastName && <p className="error">{validationErrors.lastName}</p>}
          <input type="email" name="email" placeholder="E-Mail*" value={billingDetails.email} onChange={handleInputChange} required />
          {validationErrors.email && <p className="error">{validationErrors.email}</p>}
          <input type="text" name="street" placeholder="Street*" value={billingDetails.street} onChange={handleInputChange} required />
          {validationErrors.street && <p className="error">{validationErrors.street}</p>}
          <input type="text" name="postCode" placeholder="PostCode*" value={billingDetails.postCode} onChange={handleInputChange} required />
          {validationErrors.postCode && <p className="error">{validationErrors.postCode}</p>}
          <select name="city" value={billingDetails.city} onChange={handleInputChange} required>
            <option value="">Select City*</option>
            <option value="Lim">LIMASSOL</option>
            <option value="Pfo">PAPHOS</option>
            <option value="Nic">NICOSIA</option>
            <option value="Lan">LARNACA</option>
          </select>
          {validationErrors.city && <p className="error">{validationErrors.city}</p>}
          <input type="text" name="phone" placeholder="Phone Number" value={billingDetails.phone} onChange={handleInputChange} required />
          {validationErrors.phone && <p className="error">{validationErrors.phone}</p>}
          <div className="checkbox-wrapper">
            <input id="terms-checkbox-37" name="checkbox" type="checkbox" checked={saveDetails} onChange={handleCheckboxChange} />
            <label className="terms-label" htmlFor="terms-checkbox-37">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                <mask fill="white" id="path-1-inside-1_476_5-37">
                  <rect height="200" width="200"></rect>
                </mask>
                <rect mask="url(#path-1-inside-1_476_5-37)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
              </svg>
              <span className="label-text">Save my details for future transactions</span>
            </label>
          </div>
          {saveDetailsError && <p className="error">{saveDetailsError}</p>}
        </form>
        <div className="title pb-3 pt-3">Delivery Method</div>
        <select value={deliveryMethod} onChange={handleDeliveryMethodChange}>
          <option value="">Select Delivery Method</option>
          <option value="AKIS EXPRESS">AKIS EXPRESS - 3 EUR</option>
        </select>
        {validationErrors.deliveryMethod && <p className="error">{validationErrors.deliveryMethod}</p>}

        {deliveryMethod === 'AKIS EXPRESS' && (
          <>
            <select value={deliveryLocation} onChange={handleDeliveryLocationChange}>
              <option value="">Select Delivery Location</option>
              {locationOptions.map((location, index) => (
                <option key={index} value={location}>
                  {location}
                </option>
              ))}
            </select>
            {validationErrors.deliveryLocation && <p className="error">{validationErrors.deliveryLocation}</p>}
          </>
        )}
      </div>

      {/* Order Summary */}
      <div className="order-summary">
        <h3>Order Summary</h3>
        <div className="order-items">
          {cartItems.length > 0 ? (
            cartItems.map((item, index) => (
              <div className="item" key={index}>
                <img src={item.image} alt={item.name} className="order-item-image" />
                <p>{item.quantity}x {item.name} ({item.color})</p>
                <p>€{item.price.toFixed(2)}</p>
              </div>
            ))
          ) : (
            <p>Your cart is empty</p>
          )}
        </div>

        <div className="voucher-section">
          <input type="text" placeholder="Voucher Code" className="voucher-input" value={voucherCode || ''} onChange={handleVoucherChange} />
          <button className="voucher-button" onClick={handleVoucherRedeem}>Redeem</button>
          {voucherMessage && <p className="success">{voucherMessage}</p>}
          {voucherError && <p className="error">{voucherError}</p>}
        </div>
        <div className="pb-3">
          <p>Subtotal: €{subtotal.toFixed(2)}</p>
          <p>Delivery Cost: €{shippingCost.toFixed(2)}</p>
          <p>Discount: €{discount.toFixed(2)}</p>
          <p>Delivery time: 2 - 4 working days</p>
          <h4>Total: €{total.toFixed(2)} (VAT included)</h4>
        </div>
        <button className="checkout-button" onClick={handleCheckout}>PAY NOW</button>
      </div>
    </div>
  );
};

CheckoutPage.propTypes = {
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      imageUrl: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      variant: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CheckoutPage;