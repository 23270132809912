/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../css/header.css';
import LoginDropdown from '../components/LoginDropdown';
import RegisterPopup from '../components/RegisterPopup';
import CartView from '../components/CartView';
import phoneIcon from '/media/icons/phone.svg';
import emailIcon from '/media/icons/mail.svg';
import { CartContext } from '../utils/CartContext';
import { UserContext } from '../utils/UserContext';


const Header = ({ categories = [] }) => {
  console.log('Header rendered'); // Debug statement
  const [showSearch, setShowSearch] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [isRegisterMode, setIsRegisterMode] = useState(true);
  const [cartDropdownVisible, setCartDropdownVisible] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();
  const { cartItemCount } = useContext(CartContext);
  const { user, setUser } = useContext(UserContext);
  
  const handleSearchClick = () => {
    setShowSearch(prevState => !prevState);
  };

  const handleMouseEnter = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleCartClick = () => {
    setCartDropdownVisible(!cartDropdownVisible);
  };

  const handleFavoriteClick = () => {
    navigate('/My-Account?tab=Favourites'); // Navigate to account page with favourites tab
  };

  const handleCartMouseEnter = () => {
    setCartDropdownVisible(!cartDropdownVisible);
  };

  const onSignOut = () => {
    setUser(null); // Clear user data on sign out
    navigate('/');
  };

  // const handleMouseLeave = () => {
  //   setDropdownVisible(false);
  // };

  const handleCategoryClick = (category) => {
    navigate(category.link, { state: { id: category.id } });
  };

  const handleClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const onRegisterClick = (mode) => {
    setIsRegisterMode(mode === 'join');
    setShowRegisterPopup(true);
  };


  const onCloseRegisterPopup = () => {
    setShowRegisterPopup(false);
  };

  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };


  return (
    <header>
      <div className="top-bar">
        <div className="contact-info">
        <span><img src={phoneIcon} alt="Phone Icon" className="icon-white" style={{ width: '20px', marginRight: '10px' }} />(+357) 25394245 | <img src={emailIcon} alt="Email Icon" className="icon-white" style={{ width: '20px', marginRight: '10px' }} />info@daka.com.cy</span>
        </div>
        <div className="contact-us">
          <span>Contact Us</span>
        </div>
      </div>
      <div className="middle-bar">
      <div className="logo-container">
          <Link to="/Daka-Kids">
            <img src="/media/kids-logo.svg" alt="Logo 1" className="small-logo" />
          </Link>
          <div className="separator"></div>
          <Link to="/Daka-School">
            <img src="/media/daka-school-logo.svg" alt="Logo 2" className="small-logo" />
          </Link>
          <div className="separator"></div>
          <Link to="/Daka-Workwear">
            <img src="/media/daka-workwear-1.svg" alt="Logo 3" className="small-logo" />
          </Link>
          <div className="separator"></div>
          <Link to="/Daka-Promotional">
            <img src="/media/promotional-logo.svg" alt="Logo 4" className="small-logo" />
          </Link>
        </div>
        <div className="icons-container">
        <div className="profile-icon-container" style={{ position: 'relative' }}>
            <img 
              src="/media/icons/profile.svg" 
              alt="Profile" 
              onMouseEnter={handleMouseEnter}
              onClick={handleClick} 
              className="icon"
            />
            <LoginDropdown 
              user={user}
              show={dropdownVisible} 
              onClose={() => setDropdownVisible(false)} 
              onRegisterClick={onRegisterClick} 
              onSignOut={onSignOut}/>
              {user ? <span>{user.initials}</span> : <span></span>}
              {showRegisterPopup && 
              <RegisterPopup onClose={onCloseRegisterPopup} isRegisterMode={isRegisterMode} />}
          </div>
          <img 
            src="/media/icons/favourite.svg" 
            alt="Fav" 
            className="icon" 
            onClick={handleFavoriteClick} // Add onClick handler
          />
          <div className="cart-icon-container" style={{ position: 'relative' }}>
            <img 
              src="/media/cart.svg" 
              alt="Cart" 
              onMouseEnter={handleCartMouseEnter}
              onClick={handleCartClick} 
              className="icon"
            />
            {cartItemCount > 0 && <span className="cart-counter">{cartItemCount}</span>}
            <CartView
              show={cartDropdownVisible} 
              cartItems={cartItems} 
              onClose={() => setCartDropdownVisible(false)} />
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        {/* <div className="main-logo-container">
          <img src="media/daka-logo.svg" alt="Main Logo" className="main-logo" />
        </div> */}
        <div className={`hamburger-menu ${menuActive ? 'active' : ''}`} onClick={toggleMenu}>
        <img src="/media/icons/menu.svg" alt="Menu" />
      </div>
      <nav>
        <ul className={menuActive ? 'active' : ''}>
            <li><Link to="/">HOME</Link></li>
            {Array.isArray(categories) && categories.map((category) => (
              <li key={category.id}>
                <Link to={category.link} state={{ id: category.id }}>
                  {category.name.toUpperCase()}
                </Link>
              </li>
            ))}
            <li><Link to="/Daka-All-Products">ALL PRODUCTS</Link></li>
            {/* <li><Link to="/Daka-School">SCHOOL UNIFORMS</Link></li>
            <li><Link to="/Daka-Kids">KIDS FASHION</Link></li>
            <li><Link to="/Daka-Workwear">WORKWEAR</Link></li>
            <li><Link to="/Daka-Promotional">PROMOTIONAL</Link></li>
            <li><Link to="/Daka-Promotional">ALL PRODUCTS</Link></li> */}
        </ul>
      </nav>
        {/* <div className="search-container">
          <img 
            src="/media/icons/search.svg" 
            alt="Search" 
            onClick={handleSearchClick}
            className="search-icon"
          />
          {showSearch && <input type="text" placeholder="Search..." className="search-input" />}
        </div> */}
      </div>
    </header>
  );
};

Header.propTypes = {
  user: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  })),
};

export default Header;