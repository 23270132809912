/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../css/popularproduct.css';

const PopularProducts = ({ items }) => {

  const handleItemClick = (item) => {
    // Save item data to localStorage so it persists across navigation
    localStorage.setItem('selectedItem', JSON.stringify(item));
  };

  return (
    <div className="popular-products-container">
      <div className="popular-products">
        <h2>Popular Products</h2>
        <div className="popular-products-grid">
          {items.map(item => (
        <Link
            to={`${item.id}`} // Navigate to product page with dynamic URL
            key={item.id}
            onClick={() => handleItemClick(item)} // Save the clicked item in localStorage
            className="popular-product-card"
          >
              <img src={item.mainImageUrl} alt={item.name} className="popular-product-image" />
              <div className="popular-product-details">
                <h3 className='popular-product-title'>{item.name}</h3>
                <p className='popular-product-price'>€{item.price}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

PopularProducts.propTypes = {
  items: PropTypes.array.isRequired,
};

PopularProducts.defaultProps = {
  items: [],
};

export default PopularProducts;