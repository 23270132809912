import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../css/itemgallery.css'; // Ensure you have appropriate CSS for styling

const 
ItemGallery = ({ items, removeFromFavorites }) => {
  const [sortCriteria, setSortCriteria] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Load search query and sort criteria from localStorage
  useEffect(() => {
    const savedSortCriteria = localStorage.getItem('sortCriteria');
    const savedSearchQuery = localStorage.getItem('searchQuery');
    if (savedSortCriteria) setSortCriteria(savedSortCriteria);
    if (savedSearchQuery) setSearchQuery(savedSearchQuery);
  }, []);

  // Save sort criteria and search query to localStorage when changed
  useEffect(() => {
    localStorage.setItem('sortCriteria', sortCriteria);
    localStorage.setItem('searchQuery', searchQuery);
  }, [sortCriteria, searchQuery]);

  const handleSortChange = (e) => {
    setSortCriteria(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const sortedItems = [...items].sort((a, b) => {
    if (sortCriteria === 'price') {
      return a.price - b.price;
    } else if (sortCriteria === 'name') {
      return a.name.localeCompare(b.name);
    } else if (sortCriteria === 'popularity') {
      return b.popularity - a.popularity;
    }
    return 0;
  });

  const filteredItems = sortedItems.filter(item =>
    item.price && item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate pagination details
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedItems = filteredItems.slice(startIndex, startIndex + itemsPerPage);

  const handleItemClick = (item) => {
    // Save item data to localStorage so it persists across navigation
    localStorage.setItem('selectedItem', JSON.stringify(item));
  };

  const handleRemoveClick = (e, position, itemId) => {
    e.preventDefault(); // Prevent link navigation when clicking the remove button
    console.log('Removing item with ID:', position); // Debugging line
    if (removeFromFavorites) {
      removeFromFavorites(position, itemId);
    }
  };
  

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="item-gallery">
      <div className="filter-bar">
        <select className="nam" value={sortCriteria} onChange={handleSortChange}>
          <option value="">Sort by</option>
          <option value="price">Price</option>
          <option value="name">Name</option>
          <option value="popularity">Popularity</option>
        </select>
        <input
          type="text"
          placeholder="Search items..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <div className="item-grid">
        {displayedItems.map(item => (
          <div key={item.id} className="item-wrapper">
            <Link
              to={`${item.id.toString()}`}// Navigate to product page with dynamic URL
              key={item.id}
              onClick={() => handleItemClick(item)} // Save the clicked item in localStorage
              className="item-link"
            >
              <div className="item-card">
                <img src={item.mainImageUrl} alt={item.name} className="item-image" />
                <div className="item-details">
                  <h3 className="item-title">{item.name}</h3>
                  <p className="item-price">€{item.price}</p>
                </div>
              </div>
            </Link>
            {removeFromFavorites && (
              <button
                className="remove-button"
                onClick={(e) => handleRemoveClick(e, item.position, item.id)} // Prevent link navigation when clicked
              >
                Remove from Favorites
              </button>
            )}
          </div>
        ))}
      </div>
      <div className="pagination">
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

ItemGallery.propTypes = {
  items: PropTypes.array.isRequired,
  removeFromFavorites: PropTypes.func,
};

export default ItemGallery;
